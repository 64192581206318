<template>
	<div class="container_message">
		<top-header></top-header>
		<div class="message_list width-1300">
			<el-breadcrumb separator="/">
				<el-breadcrumb-item :to="{ path: '/index'}">首页</el-breadcrumb-item>
				<el-breadcrumb-item :to="{ path: '/user'}">个人中心</el-breadcrumb-item>
				<el-breadcrumb-item>留言列表</el-breadcrumb-item>
			</el-breadcrumb>
			<div class="label">个人中心</div>
			<div class="user_center">
				<div class="top-option">
					<div class="item" @click="gourl('/user')">
						<span>基本设置</span>
					</div>
					<!-- <div class="item" @click="gourl('/cloudData')">
						<span>点云数据</span>
					</div> -->
					<div class="item active" @click="gourl('/messageList')">
						<span>留言</span>
					</div>
				</div>
				<div class="info">
					<!-- <div class="mo-label">留言</div> -->
					<div class="messsage_table">
						<el-button type="primary" @click="toMessage">我要留言</el-button>
						<el-table :data="tableData.data" style="width: 100%">
							<el-table-column prop="title" label="标题" width="180">
							</el-table-column>
							<el-table-column prop="updated_at" label="留言时间" width="180">
							</el-table-column>
							<el-table-column label="操作" align="center">
								<template slot-scope="scope">
									<el-button size="mini" type="primary" @click="handleEdit(scope.$index, scope.row)">详情
									</el-button>
									<el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">删除
									</el-button>
								</template>
							</el-table-column>
						</el-table>
					</div>
					<div class="bottom-paging">
						<el-pagination layout="prev, pager, next" small :total="tableData.total" :page-size="10"
							:pager-count="5" @current-change="handleCurrentChange" background>
						</el-pagination>
					</div>
				</div>
			</div>
		</div>
		<bottom-footer></bottom-footer>
	</div>
</template>

<script>
	import topHeader from '../../../components/header.vue'
	import bottomFooter from '../../../components/footer.vue'
	export default {
		components: {
			topHeader,
			bottomFooter,
		},
		data() {
			return {
				tableData: [],
				page: 1,
				num: 10,
			}
		},
		created() {
			this.getMessagelist();
		},
		methods: {
			toMessage() {
				this.$router.push('/message')
			},
			getMessagelist() {
				this.$get('front/comment/get_list', {
					page: this.page,
					num: this.num
				}).then(res => {
					this.tableData = res.data.data;
				})
			},
			handleCurrentChange(val) {
				// console.log(`当前页: ${val}`);
				this.page = val;
				this.getMessagelist();
			},
			gourl(url) {
				this.$router.push(url)
			},
			handleEdit(index, row) {
				//console.log(index, row);
				this.$router.push({
					query: {
						id: row.id
					},
					name: 'messageinfo'
				});
			},
			handleDelete(index, row) {
				this.$confirm("是否继续?", "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
						center: true,
					})
					.then(() => {
						this.$post("front/del/comment", {
							id: row.id,
						}).then((res) => {
							if (res.data.status == 200) {
								this.$notify({
									title: "提示",
									message: "操作成功！",
									type: "success",
								});
								this.getMessagelist();
							} else {
								this.$notify({
									title: "提示",
									message: res.data.message,
									type: "error",
								});
							}
						});
					})
					.catch(() => {
						this.$message({
							type: "info",
							message: "已取消删除",
						});
					});
			},
		}
	}
</script>

<style lang="scss">
	.container_message {
		background-color: #E8E9ED;
	}

	.el-pagination.is-background .el-pager li:not(.disabled).active {
		color: rgba(4, 29, 109, 0.77);
	}

	.message_list {
		margin-top: 60px;
		position: relative;
		padding-top: 20px;
		padding-bottom: 1px;

		@media screen and(min-width:320px) and (max-width:767px) {
		}

		.label {
			font-size: 26px;
			letter-spacing: 2px;
			margin: 30px 0;
		}
		.user_center{
			background-color: #fff;
			margin-bottom: 50px;
			box-sizing: border-box;
			height: 70.4vh;
			padding-top: 20px;
			.bottom-paging {
				margin-top: 30px;
				text-align: center;
			}
			.mo-label {
				color: #0058B1;
				display: none;
				font-weight: 800;
			
				@media screen and(min-width:320px) and (max-width:767px) {
					display: block;
					margin: 0 10px;
				}
			}
			.info{
				display: inline-block;
				margin-left: 2%;
				width: 75%;
				.messsage_table{
					& > .el-button{
						background-color: #00337D;
						border-color: #00337D;
					}
				}
				@media screen and(min-width:320px) and (max-width:767px) {
					width: 100%;
					margin-left: 0;
					padding: 10px;
					box-sizing: border-box;
				}
			}
		}

		.bottom-btn {
			margin-top: 40px;
			text-align: center;

			.el-button {
				background: linear-gradient(-36deg, #0064C8, #3995F0);
			}
		}

		.type {
			font-size: 14px;
			margin-top: 20px;

			p {
				position: relative;
				padding-left: 10px;
				margin-bottom: 15px;

				&::before {
					content: '*';
					color: red;
					position: absolute;
					top: 0;
					left: 0;
				}
			}
		}

		.title,
		.test,
		.phone {
			font-size: 14px;
			margin-top: 20px;

			textarea {
				resize: none;
				width: 100%;
				padding: 10px;
				min-height: 200px;
				box-sizing: border-box;
				border: 1px solid #bdbdbd;
				color: #bdbdbd;
				outline: none;
				border-radius: 5px;
				transition: border-color .3s;

				&:focus {
					border-color: #0064C8;
				}
			}

			p {
				position: relative;
				padding-left: 10px;
				margin-bottom: 15px;

				&::before {
					content: '*';
					color: red;
					position: absolute;
					top: 0;
					left: 0;
				}
			}

			input {
				width: 100%;
				outline: none;
				height: 40px;
				padding: 0 10px;
				box-sizing: border-box;
				border: 1px solid #bdbdbd;
				color: #bdbdbd;
				border-radius: 5px;
				transition: border-color .3s;
				-moz-appearance: textfield;

				&:focus {
					border-color: #0064C8;
				}
			}

			input[type=number]::-webkit-inner-spin-button,
			input[type=number]::-webkit-outer-spin-button {
				-webkit-appearance: none;
				margin: 0;
			}

		}

		.top-option {
			display: inline-block;
			vertical-align: top;
			width: 20%;
			border-right: 1px solid #e5e5e5;
			height: 100%;
			@media screen and(min-width:320px) and (max-width:767px) {
				display: none;
				
			}
			.item {
				padding: 13px 20px;
				font-size: 14px;
				cursor: pointer;
				transition: background-color .3s;
				&:hover {
					background-color: #E5EAF2;
					color: #00337D;
					border-right: 3px solid #00337D;
				}
			}
		
			.active {
				background-color: #E5EAF2;
				color: #00337D;
				font-weight: 800;
				border-right: 3px solid #00337D;
			}
		}
	}
</style>
